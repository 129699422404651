//  import Axios
const axios = require('axios');

export function changeLocale() {
    setLanguage('en-US' === localStorage.getItem('locale') ? 'zh-CN' : 'en-US');
}

export function setLanguage(language) {
    let lang = 'en-US' === language ? 'en' : 'zh-HK';
    axios({
        method: 'GET',
        url: '/setLanguageInSession?language=' + lang + '&url=https://' + window.location.host + window.location.pathname,
        baseURL: process.env.API_ROOT,
        withCredentials: true,
    }).then(response => {
        localStorage.setItem('locale', language);
        let url = response.data;
        window.location.href = url.replace('https:', window.location.protocol);
    }).catch(function(err) {
        console.error(err);
    });
}

export function resetLocale(language) {
    let data = new FormData();
    data.append('language', (language === 'en-US' ? 'en' : 'zh'));
    data.append('url', 'https://' + window.location.host + window.location.pathname);
    axios({
        method: 'POST',
        url: '/account/setLanguage',
        baseURL: process.env.API_ROOT,
        data: data,
        withCredentials: true,
    }).then(response => {
        localStorage.setItem('locale', language);
        window.location.href = response.data.replace('https:', window.location.protocol);
    }).catch(error => {
        console.error(error);
        window.location.reload();
    });
}
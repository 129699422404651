//  import React
import React, { PureComponent } from 'react';

//  import react-router
import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

//  App & AppContainer CSS files goes here
import './App.css';

//  import Top Container
import TopLanguageLayer from "./TopLanguageLayer";


class App extends PureComponent {

    render() {
        return (
            <Router>
                <Switch>
                    <Route path="/en" render={(props) => {
                        return <TopLanguageLayer {...props} theLanguage={'en-US'}/>
                    }} />
                    <Route path="/" render={(props) => {
                        return <TopLanguageLayer {...props} theLanguage={'zh-CN'}/>
                    }} />
                </Switch>
            </Router>
        );
    }

}

export default App;
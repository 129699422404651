//  import React
import React, { PureComponent, Suspense, lazy } from 'react';

//  import react-router
import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

//  import antd UI Framework
import { ConfigProvider } from 'antd';

// import MainContainer from "./MainContainer";
// import IndexContainer from "./IndexContainer";
// import SignUpContainer from "./SignUpContainer";
// import SignInContainer from "./SignInContainer";
// import RedirectContainer from "./RedirectContainer";

//  App & AppContainer CSS files goes here
import './App.css';

//  import intl for different language
import intl from 'react-intl-universal';

//  import locales files
import enUS from 'antd/es/locale/en_US';
import zhHK from 'antd/es/locale/zh_TW';

//  import CookieManager
import cookie from "react-cookies";

//  import Language API
import { resetLocale } from "./api/Language";

//  get locale
const locales = {
    "en-US": require('./locales/en-US.json'),
    "zh-CN": require('./locales/zh.json'),
    "zh-HK": require('./locales/zh.json'),
};

//  lazy load components
const MainContainer = lazy(() => import("./MainContainer"));
const IndexContainer = lazy(() => import("./IndexContainer"));
const SignUpContainer = lazy(() => import("./SignUpContainer"));
const SignInContainer = lazy(() => import("./SignInContainer"));
const RedirectContainer = lazy(() => import("./RedirectContainer"));

//  import Axios
const axios = require('axios');


class TopLanguageLayer extends PureComponent {

    constructor(props) {
        super(props);
        this.state = {
            antdLang: zhHK,
        };
        this.cookie = cookie;
        this.language = props.theLanguage === 'en-US' ? 'en' : 'zh-HK';
        this.loadLocales(props.theLanguage);
    }

    initIntl(lang) {
        intl.init({
            currentLocale: lang,
            locales,
        }).then(() => {
            this.setState({
                antdLang: lang === 'en-US' ? enUS : zhHK
            });
            localStorage.setItem('locale', lang === 'en-US' ? 'en-US' : 'zh-CN');
        });
    }

    loadLocales(lang) {
        if (null == lang) {
            axios({
                method: 'GET',
                url: '/loadLanguage',
                baseURL: process.env.API_ROOT,
                withCredentials: true,
            }).then(response => {
                lang = response.data.languageId === 2 ? 'en-US' : 'zh-CN';
                // if (response.data.isDefaultLanguage) {
                //     localStorage.setItem('locale', (lang == 'en-US' ? 'en-US' : 'zh-CN'));
                // }
                this.initIntl(lang);
            }).catch(error => {
                console.error(error);
            });
        } else {
            this.initIntl(lang);
        }
    }

    render() {
        return (
            <ConfigProvider locale={this.state.antdLang}>
                <div className="App">
                    <Router>

                        <Suspense fallback={<div>Loading...</div>}>

                        <Switch>

                            <Route path={"/:language/users"} render={(props) => {
                                return this.cookie.load('id') ?
                                    <MainContainer intl={intl} cookie={this.cookie} language={this.language} resetLocale={resetLocale} {...props}/> :
                                    <RedirectContainer to={"/" + this.language + "/login"} />;
                            }}/>

                            <Route path={"/:language/order/pay"} render={(props) => {
                                return this.cookie.load('id') ?
                                    <MainContainer intl={intl} cookie={this.cookie} language={this.language} resetLocale={resetLocale} {...props}/> :
                                    <RedirectContainer to={"/" + this.language + "/login"} />;
                            }} />

                            <Route exact path={"/:language" + ( (this.language === 'en') ? "/categories" : "/服務種類" ) + "/:mainCatName"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={this.language} {...props} />;
                            }} />

                            {
                                this.cookie.load('id') ?
                                    <Route path={"/:language/" + ((this.language === 'en') ? "categories": "服務種類")} render={(props) => {
                                        return <MainContainer intl={intl} cookie={this.cookie} language={this.language} resetLocale={resetLocale} {...props}/>
                                    }}/> : null
                            }

                            <Route path={"/:language/join"}  render={(props) =>{
                                return <SignUpContainer intl={intl} cookie={this.cookie} {...props}/>
                            }}/>

                            <Route path={"/:language/login"} render={(props) => {
                                return <SignInContainer intl={intl} cookie={this.cookie} {...props} />
                            }}/>

                            <Route path={"/interfaceForTools"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/uploads"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/:language/account"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/account"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/service"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/:language/service"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/:language/inbox"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/:language/management/operation"} render={(props) => {
                                return <RedirectContainer {...props} />
                            }}/>

                            <Route path={"/:language/blog/:blogUrlName"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />
                            <Route path={"/:language/網誌/:blogUrlName"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />

                            <Route path={"/:language/blog"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />
                            <Route path={"/:language/網誌"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />
                            <Route path={"/:language/user-guide/:blogUrlName"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />
                            <Route path={"/:language/用戶指南/:blogUrlName"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />

                            <Route path={"/:language/user-guide"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />
                            <Route path={"/:language/用戶指南"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />
                            }} />

                            <Route path={"/zh-HK/:sellerName/:serviceName/"} render={(props) => {
                                return this.cookie.load('id') ?
                                    <MainContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props}/> :
                                    <IndexContainer intl={intl} cookie={this.cookie} language={'zh-HK'} {...props} />;
                            }}/>
                            <Route path={"/en/:sellerName/:serviceName/"} render={(props) => {
                                return this.cookie.load('id') ?
                                    <MainContainer intl={intl} cookie={this.cookie} language={'en'} {...props}/> :
                                    <IndexContainer intl={intl} cookie={this.cookie} language={'en'} {...props} />;
                            }}/>

                            <Route path={"/"} render={(props) => {
                                return <IndexContainer intl={intl} cookie={this.cookie} language={this.language} resetLocale={resetLocale} {...props} />
                            }}/>

                        </Switch>
                        </Suspense>
                    </Router>
                </div>
            </ConfigProvider>
        );
    }
}

export default TopLanguageLayer;